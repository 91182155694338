import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typewriter from "typewriter-effect/dist/core"

// const IndexPage = () => (
function IndexPage() {
  const [blogIsShown, setBlogIsShown] = useState(false)
  const [gitIsShown, setGitIsShown] = useState(false)
  const [projectsIsShown, setProjectsIsShown] = useState(false)
  return (
    <Layout>
      <SEO title="Home" />
      <div className="mainPageLayout">
        <h1>Dean Lalap</h1>
        <h3 style={{ color: "#999" }}>Software Engineer and Musician</h3>
        <div className="mainPageLinks">
          <a
            href="https://www.ohnoibrokeit.com/"
            onMouseEnter={() => setBlogIsShown(true)}
            onMouseLeave={() => setBlogIsShown(false)}
          >
            Blog
          </a>
          <a
            href="https://www.github.com/dlalap/"
            onMouseEnter={() => setGitIsShown(true)}
            onMouseLeave={() => setGitIsShown(false)}
          >
            GitHub
          </a>
          <Link
            to="/projects/"
            onMouseEnter={() => setProjectsIsShown(true)}
            onMouseLeave={() => setProjectsIsShown(false)}
          >
            Projects
          </Link>
        </div>
        {blogIsShown ? (
          <h4>ohnoibrokeit</h4>
        ) : gitIsShown ? (
          <h4>github.com/dlalap</h4>
        ) : projectsIsShown ? (
          <h4>masterpieces lol</h4>
        ) : (
          <h4 className="invisibleText">.</h4>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage
